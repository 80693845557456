export const SPACING_XS = 8;
export const SPACING_SM = 16;
export const SPACING_MD = 32;
export const SPACING_LG = 64;

export const COLOR_PRIMARY = '#8E3557';
export const COLOR_PRIMARY_LIGHT = '#b2627f';
export const COLOR_PRIMARY_75 = 'rgba(142,52,87,0.75)';
export const COLOR_PRIMARY_50 = 'rgba(142,52,87,0.50)';

export const COLOR_SECONDARY = '#eeded0';
// export const COLOR_SECONDARY = '#efe2da';
export const COLOR_ACCENT = '#c05980';
// export const COLOR_ACCENT = '#B85059';

// export const COLOR_BLACK_SHINY = 'rgb(60, 60, 90)';

export const COLOR_GREY_LIGHT = '#f1eeeb';
export const COLOR_GREY_UNACTIVE = '#E6E6E6';

export const COLOR_GREY_DARK = '#604A51';

export const FONT_FAMILY_PRIMARY = 'bagnard';
export const FONT_FAMILY_DEFAULT = 'public-sans';
export const FONT_COLOR_DEFAULT = '#333';
export const FONT_COLOR_GREY = 'rgb(90,90,90)';

export const COLOR_BLACK_SHINY = FONT_COLOR_DEFAULT;

export const getHeaderHeight = (isMobile) => {
	return isMobile ? 42 : 50;
};

const theme = {
	fontFamily: FONT_FAMILY_DEFAULT,
	lineHeight: 1.5,

	headings: {
		fontFamily: FONT_FAMILY_PRIMARY,
		sizes: {
			h1: { fontWeight: 400 },
			h2: { fontWeight: 400, fontSize: 24 },
			h3: { fontFamily: FONT_FAMILY_DEFAULT, fontSize: 20, fontWeight: 400 },
		},
	},

	colors: {
		primary: [
			'#ffe9f3',
			'#eec6d6',
			'#dda1b8',
			'#ce7d9c',
			COLOR_ACCENT,
			'#a63f66',
			'#823150',
			'#5e2239',
			'#3a1322',
			'#1a030c',
		],
	},

	components: {
		Container: {
			styles: {
				root: {
					paddingLeft: 0,
					paddingRight: 0,
				},
			},
		},
		Title: {
			styles: {
				root: {
					color: FONT_COLOR_DEFAULT,
				},
			},
		},
		Text: {
			styles: {
				root: {
					fontFamily: 'inherit',
				},
			},
		},
		SimpleGrid: {
			defaultProps: {
				spacing: SPACING_SM,
			},
		},
		Anchor: {
			styles: {
				root: {
					color: FONT_COLOR_DEFAULT,
					textDecoration: 'underline',
				},
			},
		},
		Button: {
			defaultProps: {
				radius: 'md',
				mt: 0,
			},
			styles: {
				root: {
					marginTop: 0,
					fontWeight: 400,
					transition: '.2s',
					borderColor: COLOR_PRIMARY,
					color: COLOR_PRIMARY,

					'&:hover': {
						background: COLOR_PRIMARY,
						color: '#fff',
					},
				},
			},
		},
		Input: {
			styles: {
				input: {
					fontSize: 20,
					color: COLOR_PRIMARY,
					borderBottom: `1px solid ${COLOR_PRIMARY_50}`,

					'&::placeholder': {
						color: COLOR_PRIMARY_50,
					},

					'&:focus': {
						borderBottom: `1px solid ${COLOR_PRIMARY}`,
					},
				},
			},
		},
		Select: {
			styles: {
				input: {
					fontSize: 20,
					color: COLOR_PRIMARY,
					borderWidth: '0px 0px 1px 0px',
					borderBottom: `1px solid ${COLOR_PRIMARY_50}`,
					borderRadius: 0,

					'&::placeholder': {
						color: COLOR_PRIMARY_50,
					},

					'&:focus': {
						borderBottom: `1px solid ${COLOR_PRIMARY}`,
					},
				},
			},
		},
		Textarea: {
			styles: {
				root: {
					width: '100%',
				},
				wrapper: {
					height: '100%',
				},
				input: {
					height: '100%',
					border: `1px solid ${COLOR_PRIMARY_50}`,
					padding: SPACING_SM,

					'&::placeholder': {
						color: COLOR_PRIMARY_50,
					},

					'&:focus': {
						border: '1px solid #8E3557',
					},
				},
			},
		},
		NavLink: {
			styles: {
				root: {
					padding: 0,
					// textAlign: 'center',
					color: COLOR_SECONDARY,

					'&:hover': {
						background: 'transparent',
						color: '#fff',
					},
				},
				label: {
					fontFamily: FONT_FAMILY_PRIMARY,
					// fontSize: 48,
				},
			},
		},
	},
};

export default theme;
