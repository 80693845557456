import { Anchor, Group } from '@mantine/core';
import Image from 'next/image';
import React from 'react';

const SocialIcons = () => {
  return (
    <Group>
      <Anchor
        sx={{ opacity: 0.8, '&:hover': { opacity: 1 } }}
        target='_blank'
        href='https://www.instagram.com/lepitre.ch/'
      >
        <Image
          alt='instagram'
          src={require('../public/icons/instagram.svg')}
          height={48}
          width={48}
        />
      </Anchor>
      <Anchor
        sx={{ opacity: 0.8, '&:hover': { opacity: 1 } }}
        target='_blank'
        href='https://www.facebook.com/lepitre.ch'
      >
        <Image
          alt='facebook'
          src={require('../public/icons/facebook.svg')}
          height={48}
          width={48}
        />
      </Anchor>
    </Group>
  );
};

export default SocialIcons;
